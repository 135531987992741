import { useFetcher } from '@remix-run/react'
import { useState, Fragment, useEffect, useCallback } from 'react'
import Spinner from '~/components/ui/spinner.tsx'
import { cn } from '~/utils/misc.tsx'

interface InfiniteScrollProps<T> {
  apiEndpoint: string
  initialItems: T[]
  renderItem: (item: T) => React.ReactNode
  className?: string
}

export function InfiniteScroll<T>({
  apiEndpoint,
  initialItems,
  renderItem,
  className,
}: InfiniteScrollProps<T>) {
  const [visibleItems, setVisibleItems] = useState(initialItems)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)

  // We won't care about height until a client-side render
  const [height, setHeight] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  // Start with two because 1 was pre-loaded
  const [page, setPage] = useState(2)
  const fetcher = useFetcher()

  useEffect(() => {
    setVisibleItems(initialItems)
    setPage(2)
    setShouldFetch(true)
  }, [initialItems])

  // Set height of the parent container whenever photos are loaded
  const divHeight = useCallback(
    (node: any) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height)
      }
    },
    // update height state every time we load new items
    [visibleItems.length],
  )

  // scroll listener
  useEffect(() => {
    const scrollListener = () => {
      setClientHeight(window.innerHeight)
      setScrollPosition(window.scrollY)
    }

    // Avoid running during SSR
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', scrollListener)
    }

    // Clean up
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollListener)
      }
    }
  }, [])

  // Load data on scroll
  useEffect(() => {
    if (!shouldFetch || !height) return
    if (clientHeight + scrollPosition + 100 < height) return
    if (fetcher.state == 'loading') return
    const params = new URLSearchParams(window.location.search)
    params.set('page', page.toString())
    params.set(
      'lastDisplayOrder',
      (visibleItems[visibleItems.length - 1] as any).displayOrder,
    )
    fetcher.load(`${apiEndpoint}?${params.toString()}`)

    setShouldFetch(false)
  }, [clientHeight, scrollPosition, fetcher.state])

  // Merge data, increment page, and allow fetching again
  useEffect(() => {
    // Discontinue API calls if the last page has been reached
    console.log('fetcher.data', fetcher.data)
    if (fetcher.data && (fetcher.data as any[]).length === 0) {
      setShouldFetch(false)
      return
    }

    // Photos contain data, merge them and allow the possiblity of another fetch
    if (fetcher.data && (fetcher.data as any[]).length > 0) {
      setVisibleItems((prevItems: T[]) => [
        ...prevItems,
        ...(fetcher.data as any[]),
      ])
      setPage((page: number) => page + 1)
      setShouldFetch(true)
    }
  }, [fetcher.data])

  return (
    <>
      <div ref={divHeight} className={cn(className, 'grid grid-cols-1 gap-8')}>
        {visibleItems.map((item: T, index: number) => (
          <Fragment key={index}>{renderItem(item)}</Fragment>
        ))}
      </div>
      {fetcher.state === 'loading' && (
        <div className="flex flex-col items-center justify-center space-y-3">
          <Spinner size={10} color="text-gray-200" />
        </div>
      )}
    </>
  )
}
